import { DATE_FMT } from '@/utils/date_n_time';
import { utcToLocal } from '@/utils/date_n_time';

export default {
  name: 'PlanDetails',

  props: {
    title: {
      type: String,
      default: '',
    },
    nextLevelText: {
      type: String,
      default: '',
    },
    nextLevelDateStart: {
      type: String,
      default: '',
    },
    nextDateCharge: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    hasNextLevel() {
      return this.nextLevelDateStart && this.nextLevelText;
    },
    nextLevelDateStartFormatted() {
      return this.nextLevelDateStart ? utcToLocal(this.nextLevelDateStart, DATE_FMT.DATE) : '';
    },
    nextDateChargeFormatted() {
      return this.nextDateCharge ? utcToLocal(this.nextDateCharge, DATE_FMT.DATE) : '';
    },
  }
};
